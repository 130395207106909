<template>
    <div class="market" id="market">
        <div class="market__container container">
            <div class="market__info">
                <h3 class="title">Тебя уже ждет обновленный LEADS.MARKET</h3>
                <ul class="market__about">
                    <li class="market__about-item">
                        <p class="market__about-title">10 категорий</p>
                        <p class="market__about-text">от сертификатов до авто</p>
                    </li>
                    <li class="market__about-item">
                        <p class="market__about-title">300+ товаров</p>
                        <p class="market__about-text">без лимита покупок</p>
                    </li>
                    <li class="market__about-item">
                        <p class="market__about-title">100 заказов</p>
                        <p class="market__about-text">в среднем в месяц</p>
                    </li>
                </ul>
            </div>

            <div class="market__slider">
                <swiper
                    class="swiper"
                    :options="swiperOptionMarket"
                >
                    <swiper-slide
                        v-for="item in goods"
                        :key="item.id"
                    >
                        <div class="card accent-block">
                            <div class="card__pic accent-block">
                                <img :src="item.imagePath" :alt="item.name">
                            </div>
                            <div class="card__info">
                                <p class="card__category">{{ item.category }}</p>
                                <p class="card__name">{{ item.name }}</p>
                                <p class="card__about">{{ item.about }}</p>
                                <p class="card__price">{{ item.price }}</p>
                            </div>
                        </div>
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Market',

    data() {
        return {
            goods: [
                {
                    id: 1,
                    category: 'Подарочные сертификаты',
                    name: 'Сертификат OZON 5000 руб.',
                    about: 'Электронный, действует 1 год',
                    price: '764к',
                    imagePath: require('../assets/img/market/ozon.png'),
                },
                {
                    id: 2,
                    category: 'Подарочные сертификаты',
                    name: 'Сертификат РИВ ГОШ 5000 р.',
                    about: 'Электронный, действует 1 год',
                    price: '764к',
                    imagePath: require('../assets/img/market/rivegauche.png'),
                },
                {
                    id: 3,
                    category: 'Реклама',
                    name: 'ZaleyCash на 10 000 р.',
                    about: 'Бюджет на трафик',
                    price: '1 625к',
                    imagePath: require('../assets/img/market/zaleycash.png'),
                },
                {
                    id: 4,
                    category: 'Техника',
                    name: 'Apple iPhone 13 Pro Max',
                    about: '1 TB',
                    price: '22 878к',
                    imagePath: require('../assets/img/market/iphonama.png'),
                },
                {
                    id: 5,
                    category: 'Подарочные сертификаты',
                    name: 'Apple Macbook Air',
                    about: '13", M1, 8 яд, SSD 256 ГБ',
                    price: '14 317к',
                    imagePath: require('../assets/img/market/macbook.png'),
                },
                {
                    id: 6,
                    category: 'Сервисы',
                    name: 'Тариф Scale для Indigo',
                    about: 'На полгода',
                    price: '31 467к',
                    imagePath: require('../assets/img/market/indigo.png'),
                },
                {
                    id: 7,
                    category: 'Развлечения',
                    name: 'Тайский массаж на двоих',
                    about: 'Чаепитие после массажа',
                    price: '1 045к',
                    imagePath: require('../assets/img/market/massage.png'),
                },
            ],
            swiperOptionMarket: {
                slidesPerView: 'auto',
                spaceBetween: 24,
                centeredSlides: true,
                initialSlide: 3,
                autoplay: true,
                pagination: {
                    el: '.swiper-pagination',
                    modifierClass: 'market__',
                    clickable: true,
                },
                breakpoints: {
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                        centeredSlides: false,
                    },
                    500: {
                        slidesPerView: 'auto',
                    }
                }
            },
        }
    },
};
</script>

<style scoped>

</style>