<template>
    <div class="main-draw" id="draw">
        <div class="container">
            <div class="heading">
                <h2 class="main-draw__title title title_img">Главный розыгрыш MONEYFEST 2022</h2>
            </div>

            <div class="main-draw__block">
                <div class="main-draw__info">
                    <p class="sub-title">Получай билеты для розыгрыша за каждый выполненный этап акции</p>
                    <ul class="main-draw__list">
                        <li>Билеты выдаются за каждый выполненный этап задания.</li>
                        <li>Отдельные розыгрыши для каждого ранга, которые пройдут в финале акции.</li>
                        <li>Победителей определим через рандом, загрузив все билеты участников</li>
                    </ul>
                    <div class="main-draw__hint accent-block">
                        <p class="main-draw__hint-title">Совет</p>
                        <p class="main-draw__hint-text">Больше билетов — выше шанс получить главный приз</p>
                    </div>
                </div>
                <div class="main-draw__table">
                    <div class="rating-table">
                        <div
                            class="rating-table__item"
                            v-for="rating in ratings"
                            :key="rating.place"
                        >
                            <span class="rating-table__place">{{ rating.place }}</span>
                            <span class="rating-table__name">{{ rating.name }}</span>
                            <span class="rating-table__tickets">{{ rating.tickets }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainDraw',

    data() {
        return {
            ratings: [
                {
                    place: 1,
                    name: 'Топовый вебмастер',
                    tickets: 115,
                },
                {
                    place: 2,
                    name: 'Лью на витрину',
                    tickets: 101,
                },
                {
                    place: 3,
                    name: 'Контекст - наше все',
                    tickets: 92,
                },
                {
                    place: 4,
                    name: 'Хочу мотоцикл',
                    tickets: 87,
                },
                {
                    place: 5,
                    name: 'Лью на финансы',
                    tickets: 79,
                },
                {
                    place: 6,
                    name: 'Стану победителем Moneyfest',
                    tickets: 71,
                },
            ]
        }
    },
};
</script>

<style scoped>

</style>