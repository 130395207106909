<template>
    <div class="party">
        <div class="container">
            <div class="party__heading heading">
                <h2 class="title title_img">Лучших вебмастеров мы пригласим в монако на грандиозный финал акции</h2>
                <p class="party__text-heading text-heading">Неважно, как много ты зарабатываешь и сколько конверсий делаешь. Выполняй как можно больше заданий, участвуй в Вызовах, завершай Секретные задания. Тогда именно тебя мы пригласим в Монако* на финал акции.</p>
            </div>

            <div class="party__pic">
                <div class="party__item">
                    <span>p</span>
                    <img src="../assets/img/party/p.jpg" srcset="../assets/img/party/p@2x.jpg" alt="Акция Moneyfest от Leads">
                </div>
                <div class="party__item">
                    <span>A</span>
                    <img src="../assets/img/party/a.jpg" srcset="../assets/img/party/a@2x.jpg" alt="Акция Moneyfest от Leads">
                </div>
                <div class="party__item">
                    <span>r</span>
                    <img src="../assets/img/party/r.jpg" srcset="../assets/img/party/r@2x.jpg" alt="Акция Moneyfest от Leads">
                </div>
                <div class="party__item">
                    <span>t</span>
                    <img src="../assets/img/party/t.jpg" srcset="../assets/img/party/t@2x.jpg" alt="Акция Moneyfest от Leads">
                </div>
                <div class="party__item">
                    <span>y</span>
                    <img src="../assets/img/party/y.jpg" srcset="../assets/img/party/y@2x.jpg" alt="Акция Moneyfest от Leads">
                </div>
            </div>

            <p class="party__note">*Нам не хотелось бы, но место проведения финала может измениться из-за covid-19 и визового режима</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Party',
};
</script>

<style scoped>

</style>