<template>
    <div id="app" class="app">
        <div class="wrapper">
            <HeaderMenu />
            <Welcome />
            <Tasks class="app__tasks" />
            <Calc class="app__container" />
            <Ranks class="app__container" />
            <MainDraw class="app__container" />
            <Awards class="app__container" />
            <Party class="app__container" />
            <Additional class="app__container" />
            <Market class="app__container" />
            <Partners class="app__container" />
            <About class="app__container" />
            <News class="app__news" />
            <Footer class="app__footer" />
        </div>
    </div>
</template>

<script>
import Welcome from '@/components/Welcome';
import Tasks from '@/components/Tasks';
import Calc from '@/components/Calc';
import Ranks from '@/components/Ranks';
import Awards from '@/components/Awards';
import MainDraw from '@/components/MainDraw';
import Additional from '@/components/Additional';
import Market from '@/components/Market';
import About from '@/components/About';
import News from '@/components/News';
import Footer from '@/components/Footer';
import Party from '@/components/Party';
import HeaderMenu from '@/components/HeaderMenu';
import Partners from '@/components/Partners';

export default {
    name: 'App',

    components: {
        Welcome,
        Tasks,
        Calc,
        Ranks,
        Awards,
        Party,
        MainDraw,
        Additional,
        Market,
        Partners,
        About,
        News,
        Footer,
        HeaderMenu
    },

    mounted() {
        const mark = [
            'utm_medium',
            'utm_source',
            'utm_campaign',
            'utm_term',
            'utm_content',
            'utm_referrer',
            'click_id',
            'client_id'
        ];

        const urlParams = location.search.replace('?', '')
            .split('&')
            .filter(param => mark.find(el => param.includes(el)))
            .join('&');

        const checkUtmsLink = document.querySelectorAll('a.checkUtms');

        if (checkUtmsLink &&  checkUtmsLink.length > 0)
            addParamsToLink(checkUtmsLink, urlParams);

        function addParamsToLink(links, params) {
            if (!params)
                return;

            links.forEach(link => {
                const hasParams =  link.href.includes('?');
                link.setAttribute('href', hasParams ? link.href + '&' + params : link.href + '?' + params);
            })
        }
    }
};
</script>

<style lang="scss">
</style>
