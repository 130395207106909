<template>
    <div class="status-line">
        <div class="status-line__wrapper">
            <div
                class="status-line__block"
                v-for="status in statuses"
                :key="status.id"
            >
                <span class="status-line__point"></span>
                <span class="status-line__name">{{ status.name }}</span>
                <span class="status-line__payout" v-if="payout">
                    <template v-if="status.id === 1">До </template>
                    {{ status.payout.toLocaleString('ru-RU') }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Statuses',

    props: {
        payout: {
            type: Boolean,
            default: true,
        }
    },

    data() {
        return {
            statuses: [
                {
                    id: 1,
                    name: 'Бутлегер',
                    payout: 500000,
                },
                {
                    id: 2,
                    name: 'Маклер',
                    payout: 500000,
                },
                {
                    id: 3,
                    name: 'Банкир',
                    payout: 3500000,
                },
                {
                    id: 4,
                    name: 'Капиталист',
                    payout: 6900000,
                },
                {
                    id: 5,
                    name: 'Монополист',
                    payout: 18000000,
                },
            ]
        }
    }
};
</script>