<template>
    <div class="about" id="about">
        <div class="container">
            <div class="about__heading heading">
                <h2 class="title title_img"> Все важное о MONEYFEST 2022 в одном месте</h2>
                <p class="about__text-heading text-heading">в течение акции все вебмастера будут делиться на 5 рангов в зависимости от суммарного объема трафика на офферы спонсоров</p>
            </div>

            <Statuses class="about__status-line"/>

            <div class="about__list">
                <div class="about__item -full accent-block">
                    <div class="about__info">
                        <p class="accent-block__name">Персональные задания для каждого вебмастера</p>
                        <p class="accent-block__text">Каждый месяц получай задания по офферам спонсоров, рассчитанные специально под твои возможности</p>
                        <a class="accent-block__more" href="#tasks" v-scroll-to="'#tasks'">Подробнее</a>
                    </div>
                    <div class="about__rewards">
                        <div class="about__rewards-item accent-block">
                            <div class="about__rewards-pic">
                                <img src="../assets/img/money.png" srcset="../assets/img/money@2x.png 2x" alt="money">
                            </div>
                            <p>Денежный приз за<br> каждый этап</p>
                        </div>

                        <div class="about__rewards-item accent-block">
                            <div class="about__rewards-pic">
                                <img src="../assets/img/tickets-big.png" srcset="../assets/img/tickets-big@2x.png 2x" alt="tickets">
                            </div>
                            <p>Билеты на финальный<br> розыгрыш</p>
                        </div>
                    </div>
                </div>
                <div class="about__item accent-block">
                    <div class="about__info">
                        <p class="accent-block__name">Новые возможности</p>
                        <p class="about__item-text accent-block__text">Выполняй “Вызовы” спонсоров и секретные задания, чтобы получить дополнительные призы</p>
                        <a class="accent-block__more" href="#additional" v-scroll-to="'#additional'">Подробнее</a>
                    </div>
                </div>
                <div class="about__item accent-block">
                    <div class="about__info">
                        <p class="accent-block__name">Финальный розыгрыш</p>
                        <p class="about__item-text accent-block__text">Отдельный суперприз для каждого ранга</p>
                        <a class="accent-block__more" href="#draw" v-scroll-to="'#draw'">Подробнее</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Statuses from '@/components/Statuses';
export default {
    name: 'About',

    components: {
        Statuses,
    }
};
</script>

<style scoped>

</style>