<template>
    <div class="tasks" id="tasks">
        <div class="container">
            <div class="tasks__heading heading">
                <h2 class="title title_img">Мы сохранили механику, которая понравилась вебмастерам</h2>
                <p class="text-heading hidden-xs">и добавили еще больше призов и возможностей их получить</p>
                <p class="text-heading visible-xs">И мы уже знаем, что с ней делать в 2022</p>
            </div>

            <div class="tasks__tabs tabs" ref="tabsSwiper">
                <div class="tabs__wrapper">
                    <div class="tabs__list-container">
                        <p class="tabs__name sub-title">Ежемесячные задания от спонсоров</p>
                        <ul class="tabs__list" ref="tabs">
                            <li
                                class="tabs__item"
                                :class="{'active': tab.id === activeItem}"
                                v-for="(tab, idx) in tabs"
                                :key="tab.id"
                                @click="toSlide(idx)"
                            >
                                {{ tab.text }}
                            </li>
                        </ul>
                    </div>
                    <swiper
                        class="swiper"
                        @slideChange="slideChange($refs.swiperTasks.$swiper.activeIndex)"
                        :options="swiperOptionTabs"
                        ref="swiperTasks"
                    >
                        <swiper-slide><img src="../assets/img/task-1.png"></swiper-slide>
                        <swiper-slide><img src="../assets/img/task-2.png"></swiper-slide>
                        <swiper-slide><img src="../assets/img/task-3.png"></swiper-slide>
                        <swiper-slide><img src="../assets/img/task-4.png"></swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Tasks',
    components: {
    },
    data() {
        return {
            activeItem: 1,
            tabs: [
                {
                    id: 1,
                    text: 'Каждый месяц в твой Личный кабинет мы будем загружать для тебя персональные задания',
                    active: true,
                },
                {
                    id: 2,
                    text: 'Прими их, нажав «Принять задание»',
                    active: false,
                },
                {
                    id: 3,
                    text: 'Выполни как можно больше этапов задания до конца месяца',
                    active: false,
                },
                {
                    id: 4,
                    text: 'После закрытия отчетности получи призы за каждый выполненный этап',
                    active: false,
                },
            ],
            swiperOptionTabs: {
                slidesPerView: 'auto',
                spaceBetween: 24,
                centeredSlides: true,
            },
        }
    },

    computed: {
        setActiveTab() {

        }
    },

    methods: {
        toSlide(idx) {
            this.$refs.swiperTasks.$swiper.slideToLoop(idx, 600, false);
            this.activeItem = idx + 1;
        },

        slideChange(idx) {
            this.activeItem = idx + 1;
        }
    }
}
</script>
