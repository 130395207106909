import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

import OfferTaskUrl from '../models/OfferTaskUrl';

export default new Vuex.Store({
    state: {
        OfferTasks: [],
        selectedOptionsOffer: null,
        selectedOffer: {
            advertiser: {
                id: "",
                title: "",
            },
            offer: {
                title: "",
                payout: "",
                stat: {
                    epc: "",
                    ar: ""
                }
            },
            step: 1,
            conversion_price: 0
        },
        partners: []
    },
    getters: {
        conversionPrice: state => {
            return state.selectedOffer.conversion_price;
        },
        sortStep: state => {
            return state.selectedOffer.step;
        },
        offerSelectOptionsList: state => {
            return state.OfferTasks.map(offerTask => {
                return {
                    label: offerTask.offer.name,
                    id: offerTask.offer.id,
                };
            });
        },
    },
    mutations: {
        setOfferTask(state, payload) {
            state.OfferTasks = payload;
            state.selectedOffer = payload[0];
        },
        setSelectedOffer(state, payload) {
            state.selectedOptionsOffer = payload;
            state.selectedOffer = state.OfferTasks.filter(function (offerTask)  { return offerTask.offer.id === payload.id })[0];
        }
    },
    actions: {
        async getOfferTask({commit}) {
            let response = await axios.get(OfferTaskUrl.OFFER_TASK_LIST);
            if (response.status === 200) {
                commit('setOfferTask', response.data.data);
            }
        }
    },
    modules: {

    }
});