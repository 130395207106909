<template>
    <div class="additional" id="additional">
        <div class="container">
            <div class="additional__heading heading">
                <h2 class="title title_img">Еще больше возможностей заработать на своем трафике</h2>
                <p class="text-heading">Дополнительные возможности, которые будут введены во время акции</p>
            </div>

            <div class="additional__block -secret accent-block">
                <p class="accent-block__date">Запуск 01.02.22</p>
                <div class="additional__info">
                    <div class="additional__about">
                        <p class="accent-block__name">Секретные задания</p>
                        <p class="accent-block__text">Выполни основное задание, чтобы открыть доступ к секретному этапу
                            с премиальным бонусом по офферу, который тебе точно понравится</p>
                    </div>
                    <div class="additional__conditions">
                        <p class="additional__conditions-text">Открывается после выполнения основного задания</p>
                        <p class="additional__conditions-text">Награда — VIP-условия по офферу</p>
                    </div>
                </div>
            </div>

            <div class="additional__block -challenge accent-block">
                <p class="accent-block__date">Запуск 01.03.22</p>
                <div class="additional__info">
                    <div class="additional__about -challenge">
                        <p class="accent-block__name">Вызовы</p>
                        <p class="accent-block__text">Соревнуйся с другими вебмастерами
                            в своем ранге, стань лучшим в задании-вызове по спонсорскому офферу и получи дополнительный приз </p>
                    </div>
                    <div class="additional__conditions">
                        <p class="additional__conditions-text">Побеждает вебмастер с лучшими результатами по офферу</p>
                        <p class="additional__conditions-text">Вызовы доступны по офферам разных категорий</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Additional',
};
</script>

<style scoped>

</style>