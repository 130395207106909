<template>
    <div class="news" id="news">
        <div class="container">
            <div class="heading">
                <h2 class="title">Мы уже загрузили задания для тебя</h2>
                <p class="text-heading">Подключи их в личном кабинете и начинай зарабатывать больше</p>
                <a class="btn news__btn checkUtms" href="https://webmaster.leads.su/action2022/offerTask">Принять участие</a>
            </div>
            <ul class="news__social social">
                <li class="social__item">
                    <a class="social__link social__link_youtube" href="https://www.youtube.com/channel/UCPVtMg-ba07yycR5TEw_l7g">
                        <svg>
                            <use :href="sprite + '#icon-youtube'"/>
                        </svg>
                    </a>
                </li>
                <li class="social__item">
                    <a class="social__link social__link_vk" href="https://vk.com/leads_su">
                        <svg>
                            <use :href="sprite + '#icon-vk'"/>
                        </svg>
                    </a>
                </li>
                <li class="social__item">
                    <a class="social__link social__link_telegram" href="https://teleg.run/leadssunews">
                        <svg>
                            <use :href="sprite + '#icon-telegram'"/>
                        </svg>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import sprite from '../assets/img/sprite.svg';
export default {
    name: 'News',

    data() {
        return {
            sprite
        }
    },
};
</script>

<style scoped>

</style>