<template>
    <section class="welcome">
        <div class="container welcome__container">
            <div class="welcome__text">
                <h1 class="welcome__title title">Новая акция LEADS</h1>
                <p class="action-name welcome__action-name">MONEYFEST</p>
                <p class="welcome__title title">01.12.2021-30.11.2022</p>
            </div>
        </div>
    </section>
</template>

<script>
import HeaderMenu from '@/components/HeaderMenu';
export default {
    name: 'Welcome',

    components: {
        HeaderMenu,
    },
};
</script>

<style scoped>

</style>