<template>
    <div class="ranks" ref="ranks" id="ranks">
        <div class="container">
            <div class="ranks__heading heading">
                <h2 class="title title_img">Мы разделили всех вебмастеров на 5 рангов по величине заработка</h2>
                <p class="text-heading">и для каждого ранга сделали отдельный главный приз</p>
            </div>

            <Statuses :payout="false" />

            <div class="ranks__advantages">
                <p class="ranks__sub-title">Какая от этого польза конкретно для тебя:</p>
                <ul>
                    <li><span>Больше призов — выше шанс выиграть</span></li>
                    <li><span>Борьба только с равными тебе по объему трафика</span></li>
                    <li><span>Чем больше твои объемы, тем лучше призы</span></li>
                </ul>
            </div>
            <img class="ellipse ellipse_top-left" src="../assets/img/parallax/ellipse-top-left.png" alt="ellipse-top-left">
            <img class="ellipse ellipse_top-right" src="../assets/img/parallax/ellipse-top-right.png" alt="ellipse-top-right">
            <img class="ellipse ellipse_center" src="../assets/img/parallax/ellipse-center.png" alt="ellipse-center">
            <img class="ellipse ellipse_right" src="../assets/img/parallax/ellipse-right.png" alt="ellipse-right">
            <img class="ellipse ellipse_bottom-left" src="../assets/img/parallax/ellipse-bottom-left.png" alt="ellipse-bottom-left">
            <img class="ellipse ellipse_bottom-right" src="../assets/img/parallax/ellipse-bottom-right.png" alt="ellipse-bottom-right">
        </div>
    </div>
</template>

<script>
import Statuses from '@/components/Statuses';
import gsap from "gsap";
export default {
    name: 'Ranks',

    components: {
        Statuses,
    },

    mounted() {
        document.addEventListener('scroll', (e) => {
            this.parallax(e, '.ellipse_top-left', 80);
            this.parallax(e, '.ellipse_top-right', 40);
            this.parallax(e, '.ellipse_center', 70);
            this.parallax(e, '.ellipse_bottom-left', -90);
            this.parallax(e, '.ellipse_bottom-right', -80);
            this.parallax(e, '.ellipse_right', 60);
        });
    },
    methods: {
        parallax(e, target, movement) {
            const ranks = this.$refs.ranks.getBoundingClientRect();

            const relY = e.target.body.offsetHeight - ranks.top;

            gsap.to(target, 1, {
                y: (relY - ranks.height / 2) / ranks.height * movement,
            });
        }
    },
};
</script>

<style scoped>

</style>