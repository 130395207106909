import Vue from 'vue';
import App from './App.vue';
import store from './store';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import VueAwesomeSwiper from 'vue-awesome-swiper';

import 'swiper/css/swiper.css';
const VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo, {
    offset: -80,
});
Vue.use(VueAwesomeSwiper);

import '../src/assets/sass/style.scss';

gsap.registerPlugin(ScrollTrigger);

Vue.mixin({
    created: function () {
        this.gsap = gsap;
    }
});

Vue.config.productionTip = false;

new Vue({
    store,
    render: h => h(App)
}).$mount('#app');
