<template>
    <div class="header">
        <div class="header-menu container" :class="{'is-active': isActive}" ref="headerMenu">
            <div
                class="hamburger"
                :class="{'is-active': isActive}"
                @click="isActive = !isActive"
            >
                <div class="hamburger__box">
                    <div class="hamburger__inner"></div>
                </div>
            </div>
            <div class="mobile-menu container" ref="mobileMenu">
                <ul class="nav nav_mobile">
                    <li class="nav__item">
                        <a class="nav__link" href="#tasks" @click="closeMenu" v-scroll-to="'#tasks'">Задания</a>
                    </li>
                    <li class="nav__item">
                        <a class="nav__link" href="#draw" @click="closeMenu" v-scroll-to="'#draw'">Розыгрыш</a>
                    </li>
                    <li class="nav__item">
                        <a class="nav__link" href="#market" @click="closeMenu" v-scroll-to="'#market'">LEADS.MARKET</a>
                    </li>
                    <li class="nav__item">
                        <a class="nav__link" href="#partners" @click="closeMenu" v-scroll-to="'#partners'">Партнеры</a>
                    </li>
                </ul>
                <p class="mobile-menu__news">Следи за новостями акции в наших соцсетях</p>
                <ul class="social mobile-menu__social">
                    <li class="social__item">
                        <a class="social__link social__link_facebook" href="https://www.facebook.com/leadssuofficial/">
                            <svg>
                                <use :href="sprite + '#icon-facebook'"/>
                            </svg>
                        </a>
                    </li>
                    <li class="social__item">
                        <a class="social__link social__link_youtube" href="https://www.youtube.com/channel/UCPVtMg-ba07yycR5TEw_l7g">
                            <svg>
                                <use :href="sprite + '#icon-youtube'"/>
                            </svg>
                        </a>
                    </li>
                    <li class="social__item">
                        <a class="social__link social__link_vk" href="https://vk.com/leads_su">
                            <svg>
                                <use :href="sprite + '#icon-vk'"/>
                            </svg>
                        </a>
                    </li>
                    <li class="social__item">
                        <a class="social__link social__link_telegram" href="https://teleg.run/leadssunews">
                            <svg>
                                <use :href="sprite + '#icon-telegram'"/>
                            </svg>
                        </a>
                    </li>
                    <li class="social__item">
                        <a class="social__link social__link_inst" href="https://www.instagram.com/insta.leads/">
                            <svg>
                                <use :href="sprite + '#icon-inst'"/>
                            </svg>
                        </a>
                    </li>
                </ul>
            </div>
            <a class="btn header-menu__btn -more" href="#about" v-scroll-to="'#about'">Без подробностей</a>
            <ul class="nav header-menu__nav" ref="menu">
                <li class="nav__item">
                    <a class="nav__link scroll-to" href="#tasks" v-scroll-to="'#tasks'">Задания</a>
                </li>
                <li class="nav__item">
                    <a class="nav__link scroll-to" href="#draw" v-scroll-to="'#draw'">Розыгрыш</a>
                </li>
                <li class="nav__item">
                    <a class="nav__link scroll-to" href="#market" v-scroll-to="'#market'">LEADS.MARKET</a>
                </li>
                <li class="nav__item">
                    <a class="nav__link scroll-to" href="#partners" v-scroll-to="'#partners'">Партнеры</a>
                </li>
            </ul>
            <a class="btn header-menu__btn checkUtms" href="https://webmaster.leads.su/register">Регистрация</a>
        </div>

    </div>
</template>

<script>
import sprite from '../assets/img/sprite.svg';
export default {
    name: 'HeaderMenu',

    data() {
        return {
            isActive: false,
            sprite,
        }
    },

    created() {
        const onClickOutside = e => this.isActive = this.$el.contains(e.target) && this.isActive;
        document.addEventListener('click', onClickOutside);
        this.$on('hook:beforeDestroy', () => document.removeEventListener('click', onClickOutside));

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        window.addEventListener('resize', () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });
    },

    mounted() {
        const that = this;
        let diff = 0;

        let firstTouch = 0;
        this.$refs.mobileMenu.addEventListener('touchstart', function (e) {
            firstTouch = e.touches[0].pageX;
        });
        this.$refs.mobileMenu.addEventListener('touchmove',  function(e) {
            diff = Math.round(firstTouch - e.touches[0].pageX);
            if (diff < 30) {
                this.style.transform = 'translate3d(-'+ diff +'px, 0, 0)';
            }
        });
        this.$refs.mobileMenu.addEventListener('touchend', function (e) {
            this.style = '';

            if (diff > 30) {
                that.isActive = false;
            }
        });
    },

    methods: {
        closeMenu() {
            this.isActive = false;
        },
    },
};
</script>

<style scoped>

</style>