<template>
    <div class="partners" id="partners">
        <div class="container">
            <div class="heading partners__heading">
                <h2 class="title title_img">Партнеры акции, по офферам которых ты будешь получать задания</h2>
            </div>

            <div class="partners__list">
                <div
                    class="partners__item"
                    v-for="partner in partners"
                >
                    <img :src="partner.src">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Partners',

    data() {
        return {
            partners: [
                {
                    src: require('../assets/img/partners/webz.svg'),
                },
                {
                    src: require('../assets/img/partners/zaymigo.svg'),
                },
                {
                    src: require('../assets/img/partners/cash-u.svg'),
                },
              {
                src: require('../assets/img/partners/Alfa_Bank.svg'),
              },
                /*{
                    src: require('../assets/img/partners/gusta-credit.svg'),
                },*/
            /*    {
                    src: require('../assets/img/partners/ezaem.svg'),
                },
                {
                    src: require('../assets/img/partners/moneza.svg'),
                },*/
                {
                    src: require('../assets/img/partners/fastmoney.svg'),
                },
              /*  {
                    src: require('../assets/img/partners/credit7.svg'),
                },*/
                {
                    src: require('../assets/img/partners/platiza.svg'),
                },
                {
                    src: require('../assets/img/partners/turbozaem.svg'),
                },
                {
                    src: require('../assets/img/partners/moneyman.svg'),
                },
                {
                    src: require('../assets/img/partners/zaymer.svg'),
                },
                {
                    src: require('../assets/img/partners/mig-credit.svg'),
                },
                {
                    src: require('../assets/img/partners/lime.svg'),
                },
                {
                    src: require('../assets/img/partners/creditplus.svg'),
                },
                {
                    src: require('../assets/img/partners/e-kapusta.svg'),
                },
                {
                    src: require('../assets/img/partners/webbankir.svg'),
                },
                {
                    src: require('../assets/img/partners/money-now.svg'),
                },
                {
                    src: require('../assets/img/partners/ryabina-credit.svg'),
                },
                {
                    src: require('../assets/img/partners/oneclickmoney.svg'),
                },
                {
                    src: require('../assets/img/partners/zaymekspress.svg'),
                },
                {
                    src: require('../assets/img/partners/vivus.svg'),
                },
            /*    {
                    src: require('../assets/img/partners/cashdrive.svg'),
                },*/
                {
                    src: require('../assets/img/partners/credit-plus-ua.svg'),
                },
                {
                    src: require('../assets/img/partners/selfie-credit.svg'),
                },
              /*  {
                    src: require('../assets/img/partners/nadodeneg.svg'),
                },*/
                {
                    src: require('../assets/img/partners/viva.svg'),
                },
            ],
        };
    },
};
</script>

<style scoped>

</style>
