<template>
    <div class="calc">
        <div class="container">
            <h2 class="title title_img heading">Узнай, какая награда ждет именно тебя</h2>
            <div class="calc__wrapper">
                <div class="calc__offer">
                    <v-select
                        @input="setSelectedOffer"
                        :options="offerSelectOptionsList"
                        :value="$store.state.selectedOptionsOffer"
                    >
                                <span slot="no-options">
                                    Офферов не найдено
                                </span>
                        <template #open-indicator="{ attributes }">
                            <svg class="vs__open-indicator">
                                <use :href="sprite + '#icon-chevron-down'"></use>
                            </svg>
                        </template>
                    </v-select>

                    <div class="calc__offer_title-sm">Выбери оффер</div>
                    <div class="calc__offer_title-wrapper">
                        <div class="calc__offer_title">
                            {{ selectedOffer.offer.name || 'ошибка загрузки'}}
                        </div>
                        <div class="calc__offer_sup">
                            {{ selectedOffer.offer.id }}
                        </div>
                    </div>
                    <div class="calc__offer_table-wrapper">
                        <div>
                            <div class="calc__offer_table-title">Цель</div>
                            <div class="calc__offer_table-info">Выдача</div>
                        </div>
                        <div>
                            <div class="calc__offer_table-title">Выплата</div>
                            <div class="calc__offer_table-info">
                                {{ formatSum(selectedOffer.offer.payout) }} руб.
                            </div>
                        </div>
                        <div>
                            <div class="calc__offer_table-title">Синхронизация</div>
                            <div class="calc__offer_table-info">{{ selectedOffer.offer.sync }}</div>
                        </div>
                        <div>
                            <div class="calc__offer_table-title">Hold</div>
                            <div class="calc__offer_table-info">
                                {{ selectedOffer.offer.hold }} дней
                            </div>
                        </div>
                        <div>
                            <div class="calc__offer_table-title">EPC</div>
                            <div class="calc__offer_table-info">{{ selectedOffer.offer.stat.epc }} р.</div>
                        </div>
                        <div>
                            <div class="calc__offer_table-title">Approval rate</div>
                            <div class="calc__offer_table-info">{{ selectedOffer.offer.stat.ar }} %</div>
                        </div>
                    </div>
                    <div class="calc__offer_title-sm">Текущие конверсии</div>
                    <div class="calc__offer_value">
                        {{ formattedValue }}
                    </div>
                    <div class="calc__range-wrapper">
                        <input
                            ref="rangeInput"
                            type="range"
                            class="form-control-range styled-slider slider-progress"
                            id="formControlRange"
                            :min="min"
                            :max="1000"
                            v-model="value"
                        />
                    </div>
                    <div class="calc__offer_value-minmax">
                        <div>min: {{ min }}</div>
                        <div>max: 1 000</div>
                    </div>
                </div>
                <div class="calc__sum">
                    <div class="calc__sum_wrapper">
                        <div>
                            <div class="calc__sum_table-title">Общая награда</div>
                            <div class="calc__sum_container">
                                <div class="calc__sum_rub">₽</div>
                                <div class="calc__sum_value">
                                    {{ formatSum(sumTargets) }}
                                </div>
                            </div>
                            <div class="calc__sum_table-wrapper">
                                <div v-for="(target, i) in targets">
                                    <div class="calc__sum_table-title">Цель {{ i }}</div>
                                    <div class="calc__sum_table-info">
                                        {{ formatSum(target.targetCount) }}
                                    </div>
                                </div>

                                <div v-for="(target, i) in targets">
                                    <div class="calc__sum_table-title">Награда {{ i }}</div>
                                    <div class="calc__sum_table-info">
                                        {{ formatSum(target.reward) }} ₽
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import {mapActions, mapGetters, mapState} from 'vuex';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import sprite from '../assets/img/sprite.svg';

Vue.component('v-select', vSelect);

export default {
    name: 'Calc',

    data: () => ({
        value: 1000,
        sprite,
    }),

    computed: {
        ...mapState(['OfferTasks', 'selectedOffer']),
        ...mapGetters(['conversionPrice', 'sortStep', 'offerSelectOptionsList']),
        min: {
            get: function () {
                return 10;
            }
        },
        formattedValue() {
            return this.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        },
        targets() {
            let prevTarget = parseInt(this.value);
            let targets = {};
            for (let i = 1; i <= 3; i++) {
                let growthCount = this.roundUpStep(prevTarget * 0.1);
                let reward = parseInt(growthCount * this.conversionPrice / 1000) * 1000;
                let targetCount = prevTarget + growthCount;

                targets[i] = {
                    reward: reward,
                    growthCount: growthCount,
                    targetCount: targetCount,
                };

                prevTarget = targetCount;
            }

            return targets;
        },
        sumTargets() {
            return Object.values(this.targets).reduce((accu, target) => accu + parseInt(target.reward), 0);
        },
    },

    mounted() {
        const rangeInput = this.$refs.rangeInput;
        rangeInput.style.setProperty('--value', rangeInput.value);
        rangeInput.style.setProperty('--min', rangeInput.min == '' ? '0' : rangeInput.min);
        rangeInput.style.setProperty('--max', rangeInput.max == '' ? '100' : rangeInput.max);
        rangeInput.addEventListener('input', () => rangeInput.style.setProperty('--value', rangeInput.value));
        this.appendList();
    },

    methods: {
        ...mapActions({
            appendList: 'getOfferTask',
        }),
        formatSum: (data) => {
            return data.toLocaleString('ru-RU');
        },
        roundUpStep(value) {
            let temp = value % this.sortStep;
            let whole = parseInt(value / this.sortStep);
            whole *= this.sortStep;
            if (temp > 0) {
                whole += this.sortStep;
            }

            return parseInt(whole);
        },
        setSelectedOffer(val) {
            this.$store.commit('setSelectedOffer', val);
        },
    },
};
</script>

<style scoped>

</style>