<template>
    <div class="awards">
        <div class="container">
            <div class="awards__heading heading">
                <h2 class="title title_img">Узнай, какой приз достанется тебе</h2>
                <p class="text-heading">Увеличь свой максимальный заработок, чтобы получить новый статус или напиши менеджеру, чтобы спуститься на ранг ниже </p>
            </div>

            <div class="awards__slider">
                <swiper
                    class="swiper"
                    :options="swiperOptionAwards"
                    ref="swiperAwardsLine"
                >
                    <swiper-slide>
                        <div class="awards__item">
                            <span class="awards__name">Бутлегер</span>
                            <span class="awards__point"></span>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="awards__item">
                            <span class="awards__name">Маклер</span>
                            <span class="awards__point"></span>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="awards__item">
                            <span class="awards__name">Банкир</span>
                            <span class="awards__point"></span>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="awards__item">
                            <span class="awards__name">Капиталист</span>
                            <span class="awards__point"></span>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="awards__item">
                            <span class="awards__name">Монополист</span>
                            <span class="awards__point"></span>
                        </div>
                    </swiper-slide>
                </swiper>

                <swiper
                    class="awards__slider-bottom"
                    ref="swiperAwardsBottom"
                    :options="swiperOptionAwardsBottom"
                >
                    <swiper-slide
                        v-for="item in awardsInfo"
                        :key="item.id"
                    >
                        <div class="awards__slide">
                            <p class="awards__payout">
                                <span v-if="item.id === 1">До </span>{{ item.payout.toLocaleString('ru-RU') }} рублей
                            </p>
                            <p class="awards__text">{{ item.text }}</p>
                            <div
                                class="awards__image accent-block"
                                :style="'background-image: url('+ item.image +')'"
                            >
                                <p class="awards__image-name">{{ item.name }}</p>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
import {gsap} from 'gsap';

export default {
    name: 'Awards',

    data() {
        return {
            swiperOptionAwards: {
                slidesPerView: 3,
                centeredSlides: true,
                initialSlide: 3,
                slideToClickedSlide: true,
                allowTouchMove: false,
            },

            swiperOptionAwardsBottom: {
                slidesPerView: 1,
                centeredSlides: true,
                initialSlide: 3,
                simulateTouch: false,
                effect: 'coverflow',
                coverflowEffect: {
                    rotate: 40,
                    slideShadows: false,
                },
            },

            awardsInfo: [
                {
                    id: 1,
                    payout: 500000,
                    name: '7 Золотых слитков, 10г.',
                    text: 'твоя суммарная выплата по офферам спонсоров за весь период акции',
                    image: require('../assets/img/gold.png'),
                },
                {
                    id: 2,
                    payout: 500000,
                    name: '3 Золотых слитка, 30г.',
                    text: 'твоя суммарная выплата по офферам спонсоров за весь период акции',
                    image: require('../assets/img/gold.png'),
                },
                {
                    id: 3,
                    payout: 3500000,
                    name: '1 Золотой слиток, 100г.',
                    text: 'твоя суммарная выплата по офферам спонсоров за весь период акции',
                    image: require('../assets/img/gold.png'),
                },
                {
                    id: 4,
                    payout: 6900000,
                    name: 'Ретро-мотоцикл',
                    text: 'твоя суммарная выплата по офферам спонсоров за весь период акции',
                    image: require('../assets/img/moto.png'),
                },
                {
                    id: 5,
                    payout: 18000000,
                    name: 'Ретро-автомобиль',
                    text: 'твоя суммарная выплата по офферам спонсоров за весь период акции',
                    image: require('../assets/img/auto.png'),
                }
            ],
        }
    },

    mounted() {
        this.$nextTick(() => {
            const swiperTop = this.$refs.swiperAwardsLine.$swiper;
            const swiperBottom = this.$refs.swiperAwardsBottom.$swiper;
            swiperTop.controller.control = this.$refs.swiperAwardsBottom.$swiper;
            swiperBottom.controller.control = swiperTop;
        })
    },
};
</script>

<style scoped>

</style>